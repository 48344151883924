import React from 'react';

import { cacheRefetchUrlStateAtom } from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-query-cache-handler';
import { useClientUrl, useLayoutId } from '@/core/features/app/app-atoms';
import { useAtomValue } from '@/core/features/store/atom-store';
import zIndex from '@/core/features/styles/generated/z-index';

import styles from './loading-layer.module.scss';

export default function LoadingLayer() {
    const containerElementRef = React.useRef<HTMLDivElement>(null);
    const [clientUrl] = useClientUrl();
    const isCacheRefetch = useAtomValue(cacheRefetchUrlStateAtom) === clientUrl;
    const layoutId = useLayoutId();

    // hide loading layer on cache refetch or for S1 layouts
    if (isCacheRefetch || layoutId?.includes('S1')) {
        return null;
    }

    return (
        <div
            className={`absolute-full ${styles.loadingLayer}`}
            ref={containerElementRef}
            style={{ zIndex: zIndex('loadingLayer') }}
        />
    );
}
