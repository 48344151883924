/* This file was automatically generated */
import type { ZIndexClassName } from '@packages/styles/z-indexes';

/* eslint-disable perfectionist/sort-objects */
export const Z_INDEXES = {
    travelFormInputSearchTooltip: 40001,
    travelFormInputOverlayDesktop: 40002,
    mapBottomSheet: 40003,
    activityNavigation: 40004,
    accommodationTabs: 40005,
    mapButton: 40006,
    wireframeBlockingLayer: 40007,
    travelFormCollapsedWrapper: 40008,
    cartCta: 40009,
    dropdown: 40010,
    loadingLayer: 40011,
    wireframeMobileHeader: 41001,
    wireframeContactLayer: 41002,
    wireframeChatty: 41003,
    wireframeUliLoginLayer: 41004,
    mapTooltipArrow: 42001,
    mapTooltipBackdrop: 42002,
    notification: 42003,
    modalLayer: 42004,
    overlayTooltip: 42005,
    debugInfoBoxWrapper: 42006,
    debugInfoBox: 42007,
    hiddenMenu: 42008,
    toggleButton: 42009,
    reactQueryDevtool: 42010,
    wireframeCookieConsentWrapper: 42011,
    appSplash: 42012,
} as const;
/* eslint-enable perfectionist/sort-objects */

export default function zIndex(className: ZIndexClassName): `var(--z-index-${ZIndexClassName})` {
    return `var(--z-index-${className})`;
}
