import type { DynamicPageData } from '@/core/features/a-dynamic-page/services/dynamic-page-service';
import type { QueryKey } from '@/core/features/react-query/react-query-service';

import React from 'react';

import { PREFETCH_REQUEST_HEADER } from '@/constants/request-headers';
import {
    getDynamicPageClientSide,
    getDynamicPageQueryKey,
} from '@/core/features/a-dynamic-page/services/dynamic-page-service';
import { useQuery } from '@/core/features/react-query/react-query-service';
import { DEFAULT_QUERY_STALE_TIME } from '@/core/features/request/request-constants';
import { getUrlPathWithSearch } from '@/core/utils/url';

export default function useDynamicPageQueryPrefetch(initialHrefIdentifier: string) {
    const [isEnabled, setEnabled] = React.useState(false);

    // The query key for the disabled state has to differ from the proper one,
    // so the real prefetch does not use the invalid data from the disabled prefetch
    const queryKeyRef = React.useRef<QueryKey | null>([...getQueryKey(initialHrefIdentifier), '(disabled)']);
    const fetchHandlerRef = React.useRef<(() => Promise<DynamicPageData>) | null>(null);

    const prefetchHandler = (href: string) => {
        queryKeyRef.current = getQueryKey(href);
        fetchHandlerRef.current = () => getDynamicPageClientSide({ headers: PREFETCH_REQUEST_HEADER, url: href });

        setEnabled(true);
    };

    const query = useQuery<DynamicPageData, Error>(
        'useDynamicPageQueryPrefetch',
        queryKeyRef.current,
        fetchHandlerRef.current,
        {
            enabled: isEnabled,
            staleTime: DEFAULT_QUERY_STALE_TIME,
        },
    );

    return {
        prefetch: prefetchHandler,
        query,
    };
}

const getQueryKey = (href: string): QueryKey => {
    const url = getUrlPathWithSearch(href);
    return getDynamicPageQueryKey(url);
};
