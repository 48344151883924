import type { OnClickEvent } from '@/core/types/react-overrides';

import React, { useState } from 'react';

import { InfoIcon, ModalWithText } from '@/core/features';
import color from '@/core/features/styles/color';

type Info = {
    className?: string;
    htmlInfoText: string; //
    htmlInfoTitle: null | string;
    iconSize?: number;
};

export default function InfoIconModal({ className, htmlInfoText, htmlInfoTitle, iconSize = 14 }: Info) {
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);

    const handleOnIconClick = (event: OnClickEvent) => {
        setIsOverlayOpen(true);
        event.stopPropagation();
    };

    return (
        <>
            <InfoIcon
                className={`${className ?? ''} relative area-expansion-after pointer`}
                color={color('nobel')}
                height={iconSize}
                onClick={handleOnIconClick}
                width={iconSize}
            />
            {isOverlayOpen && (
                <ModalWithText
                    htmlText={htmlInfoText}
                    htmlTitle={htmlInfoTitle}
                    onClose={() => setIsOverlayOpen(false)}
                />
            )}
        </>
    );
}
